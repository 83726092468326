<template>
  <div>
    <div class="vx-row">
      <feather-icon @click="back()" icon="ChevronLeftIcon" style="width: 30px">
      </feather-icon>
      <p class="text-xl">
        {{ $t("Doctor Add Availability For DR: ") + doctorModel.Name }}
      </p>
    </div>

    <div class="flex items-center justify-center inset-0 ...">
      <div class="vx-row w-full">
        <div class="vx-col w-ful lg:w-1/3">
          <!-- Price card -->
          <vx-card class="vx-row m-3">
         
            <p style="font-weight: bold; color: #454a62">
              {{ $t("Online Consultation Latest Price") }}
            </p>

            <div class="vx-row pl-4 pr-4">
              <vs-input type="number" v-model="priceModel.Price" class="mt-3" />
              <!-- <p class="mt-2" style="font-size:30px">$</p> -->
              <v-select
                class="mt-3 pl-2 pr-2"
                label="Name"
                v-model="priceModel.CurrencyID"
                :options="currencies"
                :placeholder="$t('Currency')"
                :reduce="(obj) => obj.ID"
              />
            </div>
          </vx-card>
          <!-- date card -->
          <vx-card class="vx-row m-3">
            <div class="vx-col">
              <!-- day -->
              <div class="vx-col">
                <div>
                  <p style="font-weight: bold; color: #454a62" class="m-3">
                    {{ $t("Choose Days") }}
                  </p>
                </div>
                <div class="vx-row ml-2 mr-2">
                  <vs-button
                    size="small"
                    @click="model.Sunday = !model.Sunday"
                    class="m-1"
                    :color="model.Sunday ? '#009cff' : 'white'"
                    :style="[
                      model.Sunday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1; border-color: black"
                    ><span
                      :style="[
                        model.Sunday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Sun") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Monday = !model.Monday"
                    :color="model.Monday ? '#009cff' : 'white'"
                    :style="[
                      model.Monday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Monday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Mon") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Tuesday = !model.Tuesday"
                    :color="model.Tuesday ? '#009cff' : 'white'"
                    :style="[
                      model.Tuesday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Tuesday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Tues") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Wednesday = !model.Wednesday"
                    :color="model.Wednesday ? '#009cff' : 'white'"
                    :style="[
                      model.Wednesday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Wednesday
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("Wednes") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Thursday = !model.Thursday"
                    :color="model.Thursday ? '#009cff' : 'white'"
                    :style="[
                      model.Thursday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Thursday
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("Thurs") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Friday = !model.Friday"
                    :color="model.Friday ? '#009cff' : 'white'"
                    :style="[
                      model.Friday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Friday ? { color: 'white' } : { color: 'black' },
                      ]"
                    >
                      {{ $t("Fri") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    @click="model.Saturday = !model.Saturday"
                    :color="model.Saturday ? '#009cff' : 'white'"
                    :style="[
                      model.Saturday ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    style="border-radius: 34px; opacity: 1"
                    class="m-1"
                    ><span
                      :style="[
                        model.Saturday
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("Sat") }}</span
                    >
                  </vs-button>
                </div>
              </div>
              <!-- month and year-->
              <div class="vx-col m-1">
                <div>
                  <p style="font-weight: bold; color: #454a62" class="m-3">
                    {{ $t("Choose Months optional") }}
                  </p>
                </div>
                <!-- month -->
                <div class="vx-row ml-2 mr-2">
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="monthsModel.All ? '#009cff' : 'white'"
                    :style="[
                      monthsModel.All ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="initAllMonthes()"
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("All") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 20px; opacity: 1"
                    :color="
                      monthsModel.January || monthsModel.All
                        ? '#009cff'
                        : 'white'
                    "
                    :style="[
                      monthsModel.January
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.January = !monthsModel.January;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.January || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("January") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.February || monthsModel.All
                        ? '#009cff'
                        : 'white'
                    "
                    :style="[
                      monthsModel.February
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.February = !monthsModel.February;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.February || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("February") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.Mars || monthsModel.All ? '#009cff' : 'white'
                    "
                    :style="[
                      monthsModel.Mars ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.Mars = !monthsModel.Mars;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.Mars || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("Mars") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.April || monthsModel.All ? '#009cff' : 'white'
                    "
                    :style="[
                      monthsModel.April ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.April = !monthsModel.April;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.April || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("April") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.May || monthsModel.All ? '#009cff' : 'white'
                    "
                    :style="[
                      monthsModel.May ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.May = !monthsModel.May;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.May || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("May") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.June || monthsModel.All ? '#009cff' : 'white'
                    "
                    :style="[
                      monthsModel.June ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.June = !monthsModel.June;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.June || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("June") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.July || monthsModel.All ? '#009cff' : 'white'
                    "
                    :style="[
                      monthsModel.July ? {} : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.July = !monthsModel.July;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.July || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("July") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.August || monthsModel.All
                        ? '#009cff'
                        : 'white'
                    "
                    :style="[
                      monthsModel.August
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.August = !monthsModel.August;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.August || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("August") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.September || monthsModel.All
                        ? '#009cff'
                        : 'white'
                    "
                    :style="[
                      monthsModel.September
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.September = !monthsModel.September;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.September || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("September") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.October || monthsModel.All
                        ? '#009cff'
                        : 'white'
                    "
                    :style="[
                      monthsModel.October
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.October = !monthsModel.October;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.October || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("October") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.November || monthsModel.All
                        ? '#009cff'
                        : 'white'
                    "
                    :style="[
                      monthsModel.November
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.November = !monthsModel.November;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.November || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("November") }}</span
                    >
                  </vs-button>
                  <vs-button
                    size="small"
                    style="border-radius: 34px; opacity: 1"
                    :color="
                      monthsModel.December || monthsModel.All
                        ? '#009cff'
                        : 'white'
                    "
                    :style="[
                      monthsModel.December
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="
                      monthsModel.December = !monthsModel.December;
                      monthsModel.All = false;
                      viewMonth();
                    "
                    class="m-1"
                    ><span
                      :style="[
                        monthsModel.December || monthsModel.All
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ $t("December") }}</span
                    >
                  </vs-button>
                </div>
                <!-- year -->
                <div class="vx-row mt-5 ml-1 mr-1">
                  <div>
                    <p style="font-weight: bold; color: #454a62" class="m-3">
                      {{ $t("ChooseYear") }}*
                    </p>
                  </div>
                  <template>
                    <datepicker
                      :minimumView="'year'"
                      v-model="model.Year"
                      format="yyyy"
                      @input="viewMonth"
                    ></datepicker>
                  </template>
                </div>
              </div>
            </div>
          </vx-card>

          <!-- time card -->
          <vx-card class="vx-row m-3">
            <div class="vx-col">
              <div class="vx-row">
                <p style="font-weight: bold; color: #454a62" class="m-3">
                  {{ $t("From") }}
                </p>
                <vs-input type="time" v-model="model.TimeFrom"></vs-input>
              </div>
              <div class="vx-row mt-1 ml-20">
                <p style="font-weight: bold; color: #454a62" class="m-3">
                  {{ $t("To") }}
                </p>
                <vs-input type="time" v-model="model.TimeTo"></vs-input>
              </div>
              <div>
                <p>{{ $t("*The appointment intervals are 30 minutes") }}</p>
              </div>
            </div>
          </vx-card>
          <!-- buttons -->
          <div class="vx-row ml-20">
            <!-- v-if="validSessionCalendar" -->
            <u
              class="m-2"
                 :disabled="!validSessionCalendar"
              style="color: #454a62; font-weight: bold;cursor:pointer"
              @click="PreviewOnCalendar()"
              >{{ $t("Preview On Calendar") }}</u
            >
            <vs-button
              style="border-radius: 34px; opacity: 2; height: 35px"
              color="#009CFF"
              v-if="
                DoctorSessions.length > 0 && validSessionCalendar && saveFlag
              "
              @click="Save"
              class="m-1"
              :disabled="!validSessionCalendar"
              >{{ $t("Save") }}</vs-button
            >
          </div>
        </div>
        <div  class="vx-col w-full lg:w-2/3">
          <vs-card class="mt-3">
            <p
                class="mr-6"
                style="font-weight: bold; color: #454a62"
                v-if="DoctorSessions != null && DoctorSessions.length > 0"
              >
                {{ $t("DoctorPrice") }} :
                <label style="font-weight: normal">
                  {{
                    DoctorSessions[midOfDoctorSessionList].Price 
                  }}
               <span v-if="DoctorSessions[midOfDoctorSessionList].Currency">{{  DoctorSessions[midOfDoctorSessionList].Currency.SymbolEN }}</span> 

                </label>
            
              </p>

            <FullCalendar
              defaultView="dayGridMonth"
              ref="fullCalendar"
              selectable="true"
              :disabled="true"
              :options="configOptions"
              editable="true"
              :height="1000"
              :width="800"
            />
          </vs-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      baseURL: domain,
 previewMode:false,
      calendarPlugins: [
        dayGridPlugin,
        interactionPlugin,
        //  listPlugin,
        timeGridPlugin,
      ],
      doctorModel: {},
      priceModel: {},
      midOfDoctorSessionList: 0,
      SearchModel: { DoctorID: this.$route.params.ID, SetPrice: true },
      DoctorSessions: [],
      model: {
        Sunday: false,
        Saturday: false,
        Friday: false,
        Thursday: false,
        Wednesday: false,
        Tuesday: false,
        Monday: false,
        Months: [],
      },
      monthsModel: {
        All: false,
        January: false,
        February: false,
        Mars: false,
        April: false,
        May: false,
        June: false,
        July: false,
        August: false,
        September: false,
        October: false,
        November: false,
        December: false,
      },
      saveFlag: false,
      dates: ["2021-07-01", "2021-07-06"],
      isMultiSelection: true,
      values: [
        new Date("7/7/2021"),
        new Date("15/7/2021"),
        new Date("9/7/2021"),
      ],
    };
  },
  components: {
    FullCalendar,
    dayGridPlugin,
    listPlugin,
    interactionPlugin,
    timeGridPlugin,
    Datepicker,
  },
  computed: {
    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.DoctorSessions,
        weekends: this.weekendsVisible,
        datesSet: this.handleMonthChange,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        initialView: "dayGridMonth",
      };
    },
    validSessionCalendar() {
      return (
        this.model.TimeFrom != undefined &&
        this.model.TimeTo != undefined &&
        this.priceModel.Price > 0 &&
        this.priceModel.CurrencyID > 0 &&
        (this.model.Sunday ||
          this.model.Monday ||
          this.model.Tuesday ||
          this.model.Wednesday ||
          this.model.Thursday ||
          this.model.Friday ||
          this.model.Saturday)
      );
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
  },
  methods: {
    viewMonth() {
      var vm = this;

      debugger;
      this.changeMonthesStringToNumberList();
      //this.monthsModel;

      if (this.model.Months.length == 1 && this.model.Year != null) {
        debugger;
        var goTo = new Date(
          this.model.Year.getFullYear(),
          this.model.Months[0] - 1,
          1
        );
        debugger;
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(goTo);
        debugger;
      } else if (this.model.Months.length == 12 && this.model.Year != null) {
        var goTo = new Date(this.model.Year.getFullYear(), 0, 1);
        debugger;
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(goTo);
        debugger;
      }
    },
    formatJsDate(dateToFormat) {
      debugger;
      return (
        dateToFormat.getFullYear() +
        "-" +
        (dateToFormat.getMonth() + 1) +
        "-" +
        dateToFormat.getDate()
      );
    },
    handleMonthChange(arg) {
      if (this.calendarStart != arg.startStr&&!this.previewMode) {
        this.calendarStart = arg.startStr;
        this.SearchModel.DateFrom = this.formatJsDate(arg.view.activeStart);
        this.SearchModel.DateTo = this.formatJsDate(arg.view.activeEnd);
        this.getDoctorSessions(this.SearchModel);
      }
    },
    back() {
      this.$router.go(-1);
    },
    getSesionPrice() {
      this.$vs.loading();

      this.$store
        .dispatch(
          "DoctorList/GetDoctorSessionPriceByDoctorID",
          this.$route.params.ID
        )
        .then((res) => {
          if (res.status == 200) {
            this.priceModel = res.data.Data;
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });
    },
    getDoctorSessions(model) {
      this.DoctorSessions = [];
      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/SimpleSearchDoctorSessions", model)
        .then((res) => {
          this.DoctorSessions = res.data.Data;
          this.$vs.loading.close();

          debugger;
          this.midOfDoctorSessionList = this.DoctorSessions.length;
           if(this.DoctorSessions.length==1){
             this.midOfDoctorSessionList = 0;
           }
          else if (this.DoctorSessions.length % 2 == 0) {
            this.midOfDoctorSessionList = this.midOfDoctorSessionList / 2;
            }
        else {
            this.midOfDoctorSessionList = (this.midOfDoctorSessionList + 1) / 2;
          }
         
          debugger;
          // this.DoctorSessions;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    Save() {
      this.changeMonthesStringToNumberList();
      this.$vs.loading();
      this.model.DoctorID = this.$route.params.ID;
      this.model.Price = this.priceModel.Price;
      this.model.CurrencyID = this.priceModel.CurrencyID;
      this.previewMode = false;

      this.$store
        .dispatch("DoctorList/SaveDoctorSession", this.model)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();

            window.showAddSuccess();

        this.$router.go(-1);

                this.model= {
                Sunday: false,
                Saturday: false,
                Friday: false,
                Thursday: false,
                Wednesday: false,
                Tuesday: false,
                Monday: false,
                Months: [],
              };
            this.SaveDoctorSessionPrice();

          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });

      
    },
    SaveDoctorSessionPrice() {
      this.$store
        .dispatch("DoctorList/SaveDoctorSessionPrice", this.priceModel)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });
    },
    initAllMonthes() {
      if (this.monthsModel.All == false) {
        this.monthsModel = {
          All: true,
          January: false,
          February: false,
          Mars: false,
          April: false,
          May: false,
          June: false,
          July: false,
          August: false,
          September: false,
          October: false,
          November: false,
          December: false,
        };
      } else {
        this.monthsModel.All = false;
      }
      this.viewMonth();
    },
    changeMonthesStringToNumberList() {
      this.model.Months = [];
      if (this.monthsModel.All == true) {
        this.model.Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      } else {
        if (this.monthsModel.January == true) {
          this.model.Months.push(1);
        }
        if (this.monthsModel.February == true) {
          this.model.Months.push(2);
        }
        if (this.monthsModel.Mars == true) {
          this.model.Months.push(3);
        }
        if (this.monthsModel.April == true) {
          this.model.Months.push(4);
        }
        if (this.monthsModel.May == true) {
          this.model.Months.push(5);
        }
        if (this.monthsModel.June == true) {
          this.model.Months.push(6);
        }
        if (this.monthsModel.July == true) {
          this.model.Months.push(7);
        }
        if (this.monthsModel.August == true) {
          this.model.Months.push(8);
        }
        if (this.monthsModel.September == true) {
          this.model.Months.push(9);
        }
        if (this.monthsModel.October == true) {
          this.model.Months.push(10);
        }
        if (this.monthsModel.November == true) {
          this.model.Months.push(11);
        }
        if (this.monthsModel.December == true) {
          this.model.Months.push(12);
        }
      }
    },
    PreviewOnCalendar() {
      debugger
      if (this.validSessionCalendar) {
        this.changeMonthesStringToNumberList();
        this.$vs.loading();
        this.model.Price = this.priceModel.Price;
        this.model.CurrencyID = this.priceModel.CurrencyID;
        this.$store
          .dispatch("DoctorList/PreviewOnCalendar", this.model)
          .then((res) => {
            if (res.status == 200) {
              this.DoctorSessions = res.data.Data.Sessions;
              this.$vs.loading.close();
              if(this.DoctorSessions.length>0){
                this.previewMode=true;
                if(this.DoctorSessions.length=1){
                   this.midOfDoctorSessionList = 0;
                 }
                  else if (this.DoctorSessions.length % 2 == 0) {
                    this.midOfDoctorSessionList = this.midOfDoctorSessionList / 2;
                  } else {
                    this.midOfDoctorSessionList = (this.midOfDoctorSessionList + 1) / 2;
                  }
              }
                
            }
          })
          .catch(() => {
            this.$vs.loading.close();

            window.showError();
          });
        this.saveFlag = true;
      }
    },
  },
  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }

    this.$store.dispatch("CurrencyList/GetAllCurrencies");
    this.getSesionPrice();
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$store.dispatch("DoctorList/GetDoctor", ID).then((res) => {
        this.doctorModel = res.data.Data;
      });
    }
  },
  mounted() {
    var vm = this;

    vm.$refs.fullCalendar.getApi().next();
         
            
    setTimeout(function () {
       //if(vm.DoctorSessions==null||vm.DoctorSessions.length==0)
        {
               vm.$refs.fullCalendar.getApi().prev();
        }
    }, 500);
  },
};
</script>
<style lang='scss' id="style">
</style>
<style>
.wrap {
  margin: 0px auto;
  max-width: 250px;
}
</style>
